import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from 'react';
import { DropdownChangesChecked } from "../Components/FormElements/DropdownChangesChecked";
import { useOrganizationsSelectionList } from "../Persistence/OrganizationsContext";
import { useUserList } from "../Persistence/UserContext";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { getTranslation } from "../snippets";

const CLASSNAME = 'RequestDistributionAssignUserForm';
const NAMESPACE = ENUM_NAMESPACES.REQUESTDISTRIBUTIONS
export const RequestDistributionAssignUserForm = ({ formik }) => {
	const userList = useUserList();
	const organizationsSelectionList = useOrganizationsSelectionList();
	const [organization, setOrganization] = useState(null);
	const [assignUserList, setAssignUserList] = useState([]);

	useEffect(() => {
		const tempList = organization && userList ? userList.filter(entry => entry.organizationId === organization.id) : userList;
		formik.setFieldValue('newAssignUser', null)
		setAssignUserList(tempList);

	}, [organization, userList])

	return <div className='col'>
		<div className="formgrid grid">
			<div className="field col">
				<div className='card'>
					<div className={" field col mr-3 "}>
						<label htmlFor='organization' className='block'>{getTranslation(NAMESPACE, 'editdialog-asign.organization.label')}</label>
						<Dropdown
							id="organization"
							showClear
							filter
							name="organization"
							optionLabel="name"
							value={organization}
							options={organizationsSelectionList}
							onChange={(e) => setOrganization(e.value)} autoFocus className={'bg-white w-full'}
						/>
					</div>
					<DropdownChangesChecked
						NAMESPACE={NAMESPACE}
						translationKey='editdialog-asign.newAssignUser'
						filter={true}
						id={'newAssignUser'}
						optionLabel={'displayname'}
						formik={formik}
						options={assignUserList}
						showClear={true} />
				</div>
			</div>
		</div>
	</div>
}

export const emptyAssignUser = { newAssignUser: null }
export const validateAssignUserForm = (data) => {
	let errors = {};
	if (!data.newAssignUser) {
		errors.newAssignUser = 'Ein/e Benutzer*in muss ausgewählt sein.';
	}
	return errors;
}