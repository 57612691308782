import { format, parseISO } from "date-fns";
import { useFormik } from "formik";
import { Tag } from "primereact/tag";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Components/Icons";
import { BasicSheetLayout } from "../Components/Layouts/BasicSheetLayout";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { deleteData, getData, patchData } from "../feathers";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useConfigurationsList, useFormatDate } from "../Persistence/ConfigurationsContext";
import { useCurrentUserIsAdmin } from "../Persistence/CurrentUserContext";
import { useRegionsList } from "../Persistence/RegionsContext";
import { useTaskareasList } from "../Persistence/TaskareasContext";
import { logDefault } from "../Utils/logger";
import { ToastError } from "../Utils/ToastError";
import { RequestDistributionUsersSheet } from "./RequestDistributionUsersSheet";
import { emptyRegionsAndTaskareas, RequestDistributionRegionAndTaskareaForm, validateRegionsAndTaskareas } from "./RequestDistributionRegionAndTaskareaForm";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { useErrorUpdate } from "../Persistence/ErrorContext";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { getTranslation } from "../snippets";
import { BasicSheetSubtitle } from "../Components/Layouts/BasicSheetSubtitle";
import { BasicSheetTitleLayout } from "../Components/Layouts/BasicSheetTitleLayout";

const CLASSNAME = 'RequestDistributionData';
const SERVICENAME = ENUM_SERVICES.REQUESTDISTRIBUTIONS;
const NAMESPACE = ENUM_NAMESPACES.REQUESTDISTRIBUTIONS;
export const RequestDistributionData = () => {
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate();
	const isAdmin = useCurrentUserIsAdmin();
	const configurationsList = useConfigurationsList();
	const regionsList = useRegionsList();
	const taskareasList = useTaskareasList();
	const { requestDistributionId } = useParams();
	const [requestDistribution, setRequestDistribution] = useState();
	const [possibleUsers, setPossibleUsers] = useState(0);
	const [displayRegionAndTaskareaDialog, setDisplayRegionAndTaskareaDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && !configurationsList.eaaModules.REQUESTDISTRIBUTION) {
			errorUpdate('Modul Aufgabenverteilung', 'Das Modul Aufgabenverteilung ist in dieser Instanz nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		}
	})

	useEffect(() => {
		if (regionsList.length > 0 && taskareasList.length > 0 && requestDistributionId) {
			getRequestDistribution(requestDistributionId)
		}
	}, [requestDistributionId, regionsList, taskareasList])

	const getRequestDistribution = async (id) => {
		await getData(SERVICENAME, id).then((requestDistribution) => {
			if (requestDistribution.status !== 0) {
				errorUpdate('Diese Aufgabe wurde bereits zugeweisen', 'Diese Aufgabe wurde bereits einer Benutzer*in zugeordnet.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
				navigate('/' + ENUM_ROUTES.ERROR)
			} else {
				requestDistribution.regions = requestDistribution.regions.map(r => regionsList.find(e => e.id === r.id));
				requestDistribution.taskareas = requestDistribution.taskareas.map(t => taskareasList.find(e => e.id === t.id))
				requestDistribution.html_message = requestDistribution.message.html_body && requestDistribution.message.html_body.length > 2 ? requestDistribution.message.html_body : requestDistribution.message.plain_body
				requestDistribution.attachments = requestDistribution.message.attachments || []
				logDefault(CLASSNAME, 'info', 'getRequestDistribution:', requestDistribution.regions);
				setRequestDistribution(requestDistribution)
			}
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
		});
	}

	const editRegionsAndTaskareas = async () => {
		formikRegionsAndTaskareas.setValues(requestDistribution);
		setDisplayRegionAndTaskareaDialog(true)
	}

	const patchRegionsAndTaskareas = async () => {
		const request = possibleUsers.length === 1 ? { ...formikRegionsAndTaskareas.values, newAssignedUserId: possibleUsers[0].id } : formikRegionsAndTaskareas.values
		logDefault(CLASSNAME, 'info', 'patchRegionsAndTaskareas:', request);
		await patchData(SERVICENAME, request).then((requestDistribution) => {
			setDisplayRegionAndTaskareaDialog(false);
			getRequestDistribution(requestDistribution.id)
		})
	}

	const deleteRequestDistribution = async () => {
		await deleteData(SERVICENAME, requestDistribution.id).then(() => {
			setDisplayRegionAndTaskareaDialog(false);
			navigate('/' + ENUM_ROUTES.REQUESTDISTRIBUTION)
		})
	}

	const items = [{ label: 'Region / Bereich ändern', icon: ENUM_ICONS.PENCIL, command: editRegionsAndTaskareas, disabled: !isAdmin },
	{ separator: true },
	{ label: 'löschen', icon: ENUM_ICONS.TRASH, command: () => { setDisplayConfirmDialog(true) }, disabled: !isAdmin }]

	const formikRegionsAndTaskareas = useFormik({
		initialValues: { ...emptyRegionsAndTaskareas },
		validate: validateRegionsAndTaskareas,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});


	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-delete'
				labelObject={requestDistribution}
				handleOnClick={deleteRequestDistribution}
				displayConfirmDialog={displayConfirmDialog}
				setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<EditDialog
				form={<RequestDistributionRegionAndTaskareaForm formik={formikRegionsAndTaskareas} setPossibleUsers={setPossibleUsers} key='region-taskarea-form' />}
				formik={formikRegionsAndTaskareas}
				patchForm={patchRegionsAndTaskareas}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog-change'}
				setDisplayDialog={setDisplayRegionAndTaskareaDialog}
				displayDialog={displayRegionAndTaskareaDialog}
			/>

		</>
	}

	const downloadAttachment = (filename) => {
		const attachment = requestDistribution.attachments.find(attachment => attachment.filename === filename);
		const data = `data:${attachment.content_type};base64,${attachment.data}`;
		let a = window.document.createElement("a");
		a.href = data;
		a.download = filename;
		a.click();
	}

	const renderAttachments = () => {
		const attachments = requestDistribution.attachments || [];
		return attachments.map((attachment) => {
			const icon = attachment.filename.endsWith('.xlsx') ? ENUM_AWESOMEICON.FILEEXCEL : attachment.filename.endsWith('.pdf') ? ENUM_AWESOMEICON.FILEPDF : attachment.filename.endsWith('.docx') ? ENUM_AWESOMEICON.FILEWORD : ENUM_AWESOMEICON.FILEALT
			return <Button key={'button-attachment-' + attachment.filename} className=" p-0 " aria-label="" onClick={() => { downloadAttachment(attachment.filename) }}>
				<Icon ENUM_AWESOMEICON={icon} size={ENUM_AWESOMEICON_SIZE.XL} className='attachment-button-icon' />
				<span className="px-3 attachment-button-text">{attachment.filename}</span>
			</Button>
		})
	}

	const renderContent = () => {
		return <div className="eaa-maincontent flex flex-column">
			<div className='flex m-0 w-full h-full '>
				<div className='mx-auto eaa-maincontent flex flex-column'>
					<div className="flex flex-column">
						<BasicSheetTitleLayout
							listButtons={[]}
							title={requestDistribution.subject}
							subtitle={<BasicSheetSubtitle
								NAMESPACE={NAMESPACE}
								tag={!requestDistribution.taskareas ? '' : requestDistribution.taskareas.map(e => {
									return <Tag key={'taskarea-' + e.id} style={{ color: '#' + e.color, backgroundColor: '#' + e.backcolor }} className={'mr-2 text-white color-tag-' + e.color} rounded value={e.name} />
								})}
								object={requestDistribution}
							/>}
						/>
						<div className='flex overflow-hidden'>
							<FieldInfo
								NAMESPACE={NAMESPACE}
								translationKey='fields.name'
								content={requestDistribution.name}
							/>
							<FieldInfo
								NAMESPACE={NAMESPACE}
								translationKey='fields.email'
								content={<a href={`mailto:${requestDistribution.email}`}>{requestDistribution.email}</a>}
							/>
							<FieldInfo
								NAMESPACE={NAMESPACE}
								translationKey='fields.regions'
								content={requestDistribution.regions ? requestDistribution.regions.map(e => e.name).join('; ') : 'keiner Region zugeordnet'}
							/>
						</div>
					</div>

					<Divider />
					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							{getTranslation(NAMESPACE, 'fields.html_message.label')}
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: requestDistribution.html_message }} />
					</div>
					<Divider />
					<div className='flex flex-column surface-ground mx-2 mb-8 button-attachments'>
						{!requestDistribution.attachments || requestDistribution.attachments.length === 0 ? '' : <div className='flex text-600 text-base'>
							{getTranslation(NAMESPACE, 'fields.attachments.label')}
						</div>}
						<div className='flex gap-4'>
							{renderAttachments()}
						</div>

					</div>
				</div>
			</div>
		</div>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{ index: 0, visible: true, awesomeIcon: ENUM_AWESOMEICON.ASSIGN, icon: ENUM_ICONS.CALENDAR + "fa-lg", component: <RequestDistributionUsersSheet requestDistribution={requestDistribution} /> },
		]
		return sideSheets;
	}

	return (!requestDistribution ? <ToastError toastParams={toastParams} /> :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{renderContent()}{renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={items} />}
			sideSheets={getSideSheets()}
		/>
	)

}