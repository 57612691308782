import { Card } from "primereact/card"
import { useEffect, useState } from "react";
import { ENUM_ICONS } from "../Components/Icons";
import { getData } from "../feathers";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { useCurrentUserIsReader } from "../Persistence/CurrentUserContext";
import { logDefault } from "../Utils/logger";
import { CompanyAddresses } from "./CompanyAddresses"
import { CompanyChangeDialog } from "./CompanyChangeDialog";
import { employmentObligationOptions } from "./CompanyForm";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { getCompany } from "./CompanyManager";
import { CONTACTPERSON_SERVICECONFIGLIST } from "../ContactPersons/ContactPersonsForm";
import { ContactPersonsSheet } from "../ContactPersons/ContactPersonsSheet";
import { CompanyFBiBJobOpenings } from "./CompanyFBiBJobOpenings";
import { useConfigurationsList, useFBiBMode } from "../Persistence/ConfigurationsContext";
import { getTranslation } from "../snippets";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";

const NAMESPACE = ENUM_NAMESPACES.COMPANIES
const CLASSNAME = 'CompanySheet'
export const CompanySheet = ({ company, onUpdateCompany, readonly, hideSpecialFields, fbibCaseMode, fbibCaseId, onSelectCompany, favoriteContactPersons, setFavoriteContactPersons }) => {
	const configurationList = useConfigurationsList();
	const isReader = useCurrentUserIsReader();
	const addBreadcrumb = useBreadcrumbsAdd();
	const fbibMode = useFBiBMode();
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
	const [contactPersons, setContactPerson] = useState([]);
	const [addresses, setAddresses] = useState([]);

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'CompanySheet readonly: ' + readonly)
	}, [])

	useEffect(() => {
		setContactPerson((company && company.contactPersons) ? company.contactPersons.filter(e => e.active === 1) : [])
		setAddresses((company && company.addresses) ? company.addresses.filter(e => e.active === 1) : [])
	}, [company])

	const onCompanyUpdate = async (company) => {
		setDisplayCompanyDialog(false)
		onUpdateCompany(company)

	}
	const onChangeCompanyId = async (companyId) => {
		setDisplayCompanyDialog(false)
		if (onSelectCompany) {
			await getCompany(companyId).then(async (company) => {
				await onSelectCompany(company)
			})

		} else {
			await getCompany(companyId, true).then(async (company) => {
				await onUpdateCompany(company)
			})

		}
	}

	const renderHeader = () => {
		const fbibJobopening = (company && company.fbibJobOpenings ? company.fbibJobOpenings : []).find(e => {
			return fbibCaseId === e.fbibCaseId && (e.status === 'ARRANGED' || e.status === 'RESERVED')
		})
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>{getTranslation(NAMESPACE, 'sheet.headline.label')}</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>{!company ? getTranslation(NAMESPACE, 'sheet.company-not-related.label') : company.name} </div>
				<div className='flex gap-2'>
					<div className='hidden w-1rem' onClick={handleSelectCompany}><i className={ENUM_ICONS.EYE} /></div>
					<div className={isReader || readonly ? 'hidden' : 'flex w-1rem'} onClick={() => setDisplayCompanyDialog(true)}><i className={company ? ENUM_ICONS.PENCIL : ENUM_ICONS.PLUS_CIRCLE} /></div>
					<div className={fbibMode && fbibCaseMode && !fbibJobopening && company ? 'flex w-1rem' : 'hidden'} onClick={() => setDisplayDeleteDialog(true)}><i className={ENUM_ICONS.TRASH} /></div>
				</div>

			</div>
		</div>
	}

	const handleSelectCompany = async () => {
		await getData(ENUM_SERVICES.COMPANIES, company.id).then((company) => {
			addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.COMPANIES}/` + company.id);
		})
	}

	const handleDeleteCompanyInParent = async () => {
		await onSelectCompany(null).then(() => {
			setDisplayDeleteDialog(false);
		})
	}

	const renderQuote = () => {
		const employees = company && company.employees && company.employees > 0 ? company.employees : 0;
		const employeesSbM = company && company.employeesSbM ? company.employeesSbM : 0;
		const quote = employees > 0 ? (100 / employees) * employeesSbM : 0;
		return quote.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%'
	}

	const renderField = (key, value, showSpecialFields) => {
		return <div className={value !== null && showSpecialFields ? 'grid' : 'hidden'}>
			<div className="col-fixed">{getTranslation(NAMESPACE, `${key}.label`)}</div>
			<div className="col">{value}</div>
		</div>
	}

	return (
		<>
			<Card className={'companysheet card-sheet border-none mb-3'} header={renderHeader}>
				{renderField('fields.phone', company?.phone, !hideSpecialFields)}
				{renderField('fields.fax', company?.fax, !hideSpecialFields)}
				{renderField('fields.mobile', company?.mobile, !hideSpecialFields)}
				{renderField('fields.mail', company?.mail, !hideSpecialFields)}
				{renderField('fields.employees', company?.employees, true)}
				{renderField('fields.employeesSbM', company?.employeesSbM, true)}
				{renderField('fields.branch', company?.branch?.name, true)}
				{renderField('fields.chamber', company?.chamber, true)}
				{renderField('fields.chamberNo', company?.chamberNo, true)}
				{renderField('fields.region', company?.region?.name, true)}
				{renderField('fields.employmentObligation', employmentObligationOptions.find(entry => entry.value === company?.employmentObligation)?.display, true)}
				{renderField('fields.quote', renderQuote(), true)}
				{configurationList.eaaModules.DATAPROTECTIONSHEET !== '1' ? '' :
					renderField('fields.dataprotectionsheet', getTranslation(NAMESPACE, `fields.dataprotectionsheet.${company?.dataprotectionsheet}.label`), true)
				}
			</Card>
			<CompanyChangeDialog
				companyId={company ? company.id : null}
				displayCompanyDialog={displayCompanyDialog}
				setDisplayCompanyDialog={setDisplayCompanyDialog}
				handleOnCompanyPatched={onCompanyUpdate}
				changeCompanyId={company ? undefined : onChangeCompanyId} />

			{!company ? '' : <ContactPersonsSheet
				parentId={company.id}
				contactPersons={contactPersons}
				addressList={addresses}
				numCarousel={1}
				readonly={readonly}
				handleUpdateParent={onUpdateCompany}
				CONTACTPERSON_SERVICECONFIG={CONTACTPERSON_SERVICECONFIGLIST.COMPANY}
				favoriteContactPersons={favoriteContactPersons}
				setFavoriteContactPersons={setFavoriteContactPersons} />}


			{!company || (fbibMode && fbibCaseMode) ? '' : <CompanyAddresses
				readonly={readonly}
				hideDivider={true}
				company={company}
				addresses={addresses}
				numCarousel={1}
				onUpdateCompany={onUpdateCompany}
			/>}
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-remove'
				labelObject={{ name: company.name }}
				handleOnClick={handleDeleteCompanyInParent}
				displayConfirmDialog={displayDeleteDialog}
				setDisplayConfirmDialog={setDisplayDeleteDialog}
			/>
			{company && fbibMode && fbibCaseMode ? <CompanyFBiBJobOpenings
				readonly={readonly}
				company={company}
				numCarousel={1}
				onUpdateCompany={onUpdateCompany}
				fbibCaseId={fbibCaseId}
				fbibCaseMode={fbibCaseMode}
			/> : ''}
		</>
	)

}