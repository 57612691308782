import { Button } from "primereact/button"
import { copyObject, formatDate, getTranslation } from "../../snippets"
import { useEffect, useRef, useState } from "react";
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";
import { parseISO } from "date-fns";

export const getScreenreaderMessage = (objectname, object, NAMESPACE) => {
	if (!object) return
	const srObject = { ...copyObject(object), objectname, createdAtFormatted: formatDate(object.createdAtMillis || parseISO(object.createdAt)), creatorname: object.creator?.displayname }
	return getTranslation(NAMESPACE || ENUM_NAMESPACES.FORMELEMENTS, 'screenreader-message', srObject)
}

export const BasicSheetTitleLayout = ({ listButtons, title, subtitle, overlaypanel, screenReaderMessage }) => {
	const refMain = useRef();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (mounted) {
			refMain.current.focus()
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	const renderButtons = () => {
		return listButtons.map(button => {
			const labels = button.translationKey ? getTranslation(button.NAMESPACE, button.translationKey, { returnObjects: true }) : { label: button.label, ariaLabel: button.label }
			return button.hidden ? '' : button.component || <Button
				onClick={button.command}
				key={button.key}
				icon={button.icon}
				disabled={button.disabled}
				label={labels.label}
				aria-label={labels.ariaLabel}
				className='flex text-white button-basictitlelayout color-background-blue'
			/>
		})
	}

	return (
		<div className='flex flex-column '>
			<div tabIndex={0} ref={refMain} aria-live="polite" className="screen-reader-only">{screenReaderMessage}</div>
			<div className='flex justify-content-end mb-4 gap-2'>
				{renderButtons()}
			</div>
			<div className='flex grid'>
				<div className='col flex flex-column text-4xl '>
					<div className="flex mt-0 text-4xl font-semibold justify-content-center text-center mx-8">
						{title}
					</div>
					<div className='flex text-lg justify-content-center my-2 gap-2'>
						{subtitle}
					</div>
				</div>
				<div className={overlaypanel ? 'col-fixed w-2rem' : 'hidden'}>
					{overlaypanel}
				</div>
			</div>
		</div>)
}