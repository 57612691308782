import { parseISO } from 'date-fns';
import { FilterService, FilterMatchMode } from 'primereact/api';

FilterService.register("multiIds", (value, filter) => { return !filter ? true : value.filter((element: any) => filter.includes(element.id)).length > 0 });
FilterService.register("ArrayContainsMatchMode", (value, filter) => { return !filter ? true : value.filter((element: any) => filter.includes(element)).length > 0 });

export enum ENUM_DATAVIEWS_CONFIG {
	EAACASES = 'EAACASES',
	FBIBCASES = 'FBIBCASES',
	COMPANIES = 'COMPANIES',
	PROVIDERACTIVITIES = 'PROVIDERACTIVITIES',
	ASSOCIATEDCONTACTS = 'ASSOCIATEDCONTACTS',
	REQUESTDISTRIBUTIONS_USERMATRIX = 'REQUESTDISTRIBUTIONS_USERMATRIX',
	REQUESTDISTRIBUTIONSARCHIV = 'REQUESTDISTRIBUTIONSARCHIV',
	REQUESTDISTRIBUTIONS = 'REQUESTDISTRIBUTIONS',
	REMINDERS = 'REMINDERS',
	JOBOPENINGS = 'JOBOPENINGS',
	USERADMINISTRATION = 'USERADMINISTRATION',
	CASEREQUESTTYPES = 'CASEREQUESTTYPES',
	REGIONS = 'REGIONS',
	ORGANIZATIONS = 'ORGANIZATIONS',
	TASKAREAS = 'TASKAREAS',
	TRAININGCENTERS = 'TRAININGCENTERS',
	OVERVIEWS_ASSOCIATEDCONTACTS = 'OVERVIEWS_ASSOCIATEDCONTACTS',
	OVERVIEWS_CASEEVENTS = 'OVERVIEWS_CASEEVENTS',
	OVERVIEWS_CASEREQUESTS = 'OVERVIEWS_CASEREQUESTS',
	OVERVIEWS_CASEPERSONS = 'OVERVIEWS_CASEPERSONS',
	OVERVIEWS_RELATEDUSERS = 'OVERVIEWS_RELATEDUSERS',
	OVERVIEWS_COMPANYADDRESSES = 'OVERVIEWS_COMPANYADDRESSES',
	OVERVIEWS_COMPANYCONTACTS = 'OVERVIEWS_COMPANYCONTACTS',
	OVERVIEWS_PROVIDERACTIVITIES = 'OVERVIEWS_PROVIDERACTIVITIES',
	OVERVIEWS_EAACASESREVIEW = 'OVERVIEWS_EAACASESREVIEW',
	FBIBPROVIDERACTIVITIES = 'FBIBPROVIDERACTIVITIES',
	OVERVIEWS_FBIBPROVIDERACTIVITIES = 'OVERVIEWS_FBIBPROVIDERACTIVITIES',
	TRAININGCOURSES = 'TRAININGCOURSES',
	FBIBJOBOPENINGS = 'FBIBJOBOPENINGS',
	STATISIC_COMPANYCONTACTS = 'STATISIC_COMPANYCONTACTS',
	ACTIVITIES_INCLUSIONOFFICE = 'ACTIVITIES_INCLUSIONOFFICE'
};

export type DATAVIEW_CONFIG_ENTRY = {
	id: ENUM_DATAVIEWS_CONFIG,
	key: string,
	alias: ENUM_DATAVIEWS_CONFIG,
	headline?: string,
	service: string,
	filtername: string,
	filters: object,
	sort: object,
	pagination: object,
	exportFilename: string,
	exportFunction?: Function
	clearDataValue?: Function,
}

export type DATAVIEW_CONFIG_TYPE = {
	[ENUM_DATAVIEWS_CONFIG.EAACASES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.COMPANIES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.PROVIDERACTIVITIES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.ASSOCIATEDCONTACTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONS_USERMATRIX]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONSARCHIV]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REMINDERS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.JOBOPENINGS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.USERADMINISTRATION]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.CASEREQUESTTYPES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.REGIONS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.ORGANIZATIONS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.TASKAREAS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.TRAININGCENTERS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_EAACASESREVIEW]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEPERSONS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.FBIBJOBOPENINGS]: DATAVIEW_CONFIG_ENTRY,
	[ENUM_DATAVIEWS_CONFIG.ACTIVITIES_INCLUSIONOFFICE]: DATAVIEW_CONFIG_ENTRY,
}

const DEFAULT_COMPANIES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'responsible.id': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', filterElementClassName: '' },
	'region.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Region suchen' },
	'millisecondsCreatedAt': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: '', filterPlaceholder: '' },
}

export const DEFAULT_EAACASES_FILTER = {
	col_01: { value: null, matchMode: FilterMatchMode.CONTAINS },
	col_04: { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fallname suchen' },
	col_03: { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	col_05: { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date(), instance: 'DATE' },
	col_02: { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', },
	col_06: { value: null, matchMode: FilterMatchMode.IN, filterListName: 'CaseStatusList', filterElement: 'multiTagFilterElement', filterSelectedLabel: 'Status', filterPlaceholder: 'Status wählen', maxSelectedLabels: 3 },
}

export const getDefaultFilter = (settings: any, DataviewsConfig: DATAVIEW_CONFIG_ENTRY[]) => {
	const filters: any = {}
	for (let key of Object.keys(DataviewsConfig)) {
		const index: any = key;
		const entry: any = DataviewsConfig[index]
		const value = !settings ? undefined : (settings[entry.alias] || settings[entry.filtername])
		filters[entry.alias] = value || entry.filters

		if (filters[entry.alias].instance === 'DATE' && filters[entry.alias].maxDate) {
			filters[entry.alias].maxDate = parseISO(filters[entry.alias].maxDate)
		}
		if (filters[entry.alias].instance === 'DATE' && filters[entry.alias].value) {
			filters[entry.alias].value = parseISO(filters[entry.alias].value)
		}
	}
	return filters;
}

export const getDefaultPagination = (settings: any, DataviewsConfig: DATAVIEW_CONFIG_ENTRY[]) => {
	const paginations: any = {}
	for (let key of Object.keys(DataviewsConfig)) {
		const index: any = key;
		const entry = DataviewsConfig[index]
		const values = !settings ? undefined : (settings[entry.alias] || settings[entry.filtername])
		paginations[entry.alias] = values || entry.pagination
	}
	return paginations;
}

export const getDefaultSort = (settings: any, DataviewsConfig: DATAVIEW_CONFIG_ENTRY[]) => {
	const sorts: any = {}
	for (let key of Object.keys(DataviewsConfig)) {
		const index: any = key;
		const entry = DataviewsConfig[index]
		const values = !settings ? undefined : (settings[entry.alias] || settings[entry.filtername])
		sorts[entry.alias] = values || entry.sort
	}
	return sorts;
}